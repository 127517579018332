<template>
  <b-modal
    :id="modalId"
    size="md"
    :title="modalTitle"
    centered
    scrollable
    title-class="font-22"
    @hide="handleHideModal"
  >
    <div class="book">
      <i
        v-if="form.photo || image"
        class="mdi mdi-close-circle book__clear-icon"
        @click="handleClearCover"
      />
      <div
        class="book__cover"
        :style="{ backgroundColor: !image && !form.photo && book?.cover_color ? book?.cover_color : '#53565B' }"
      >
        <img v-if="form.photo || image" :src="image || form.photo" alt="" />
        <template v-else>
          <div class="book__title">{{ form.title }}</div>
          <hr class="book__divider" />
          <div class="book__author">{{ form.author }}</div>
        </template>
      </div>
      <div class="file-input">
        <label for="cover">
          Change Cover
          <input
            id="cover"
            type="file"
            @change="handleChangeCover"
            placeholder="Choose file"
            :multiple="false"
            hidden="hidden"
            drop-placeholder="Drop file here..."
            accept=".jpg, .jpeg, .png, .webp"
          />
        </label>
      </div>
    </div>
    <b-form-group
      label-for="title"
      label="Title"
      class="required"
    >
      <input
        type="text"
        v-model="form.title"
        id="title"
        class="form-control form-control-sm"
        :class="{ 'is-invalid': $v.form.title.$error }"
      />
    </b-form-group>
    <div class="row">
      <b-form-group
        class="required col-6"
        label-for="author"
        label="Author"
      >
        <input
          type="text"
          v-model="form.author"
          id="author"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': $v.form.author.$error }"
        />
      </b-form-group>
      <b-form-group
        class="required col-6"
        label="Category"
        label-for="Category"
      >
        <multiselect
          v-model="form.category"
          :options="categories"
          track-by="id"
          :searchable="true"
          :show-labels="false"
          label="title"
          :class="{ 'is-invalid': $v.form.category.$error }"
          placeholder="Choose Category"
        />
      </b-form-group>
      <b-form-group
        class="required col-6"
        label="Language"
        label-for="language"
      >
        <multiselect
          v-model="form.language"
          :options="languages"
          track-by="id"
          :searchable="true"
          :show-labels="false"
          label="title"
          :class="{ 'is-invalid': $v.form.language.$error }"
          placeholder="Choose Language"
        />
      </b-form-group>
      <b-form-group
        class="required col-6"
        label="Purchase Date"
        label-for="purchase-date"
      >
        <input
          type="date"
          v-model="form.purchaseDate"
          id="purchase-date"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': $v.form.purchaseDate.$error }"
        >
      </b-form-group>
      <b-form-group
        class="required col-6"
        label="Purchase Price ($)"
        label-for="purchase-price"
      >
        <input
          type="number"
          v-model="form.purchasePrice"
          id="purchase-price"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': $v.form.purchasePrice.$error }"
        >
      </b-form-group>
      <b-form-group
        class="required col-6"
        label="Shipping Cost ($)"
        label-for="shipping-cost"
      >
        <input
          type="number"
          v-model="form.shippingCost"
          id="shipping-cost"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': $v.form.shippingCost.$error }"
        >
      </b-form-group>
    </div>
    <b-form-group
      label="Description"
      label-for="description"
    >
      <textarea
        type="text"
        v-model="form.description"
        id="description"
        class="form-control form-control-sm"
      ></textarea>
    </b-form-group>
    <b-form-group
      class="required m-0"
      label="Taken By (Status)"
      label-for="employee"
    >
      <multiselect
        v-model="form.takenBy"
        :options="employees"
        track-by="uid"
        :searchable="true"
        :show-labels="false"
        label="name"
        placeholder="Choose Employee"
      />
    </b-form-group>
    <template slot="modal-footer">
      <div :class="[
        'w-100 d-flex align-items-center m-0',
        canDelete ? 'justify-content-between' : 'justify-content-end']"
      >
        <b-button
          v-if="canDelete"
          variant="danger"
          @click="handleDelete"
        >
          Delete
        </b-button>
        <b-button
          variant="primary"
          @click="handleSubmit"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { FORM_MODE } from '@/utils/constants';

const available = { name: 'Available', uid: null };

export default {
  name: 'BookModal',
  props: {
    open: Boolean,
    mode: {
      type: String,
      required: true,
      validator(value) {
        return [FORM_MODE.CREATE, FORM_MODE.EDIT].includes(value)
      }
    },
    book: Object,
    inventoryCode: Number
  },
  data: () => ({
    modalId: 'book-modal',
    form: {
      title: '',
      author: '',
      description: '',
      category: '',
      language: '',
      purchaseDate: '',
      purchasePrice: '',
      shippingCost: '',
      takenBy: available,
      photo: '',
      file: null,
    },
    image: '',
    isCoverChange: false
  }),
  validations: {
    form: {
      title: { required },
      author: { required },
      category: { required },
      language: { required },
      purchaseDate: { required },
      purchasePrice: { required },
      shippingCost: { required },
    }
  },
  methods: {
    handleClearCover() {
      this.form.photo = '';
      this.form.file = null;
      this.image = '';
      this.isCoverChange = true;
    },
    fillFormValues() {
      this.form.title = this.book.title;
      this.form.author = this.book.author;
      this.form.description = this.book.description;
      this.form.category = this.categories.find(({ id }) => id === this.book.library_item_category_id);
      this.form.language = this.languages.find(({ id }) => id === this.book.library_item_language_id);
      this.form.purchaseDate = this.book.purchase_date ?? '';
      this.form.purchasePrice = this.book.purchase_price ?? '';
      this.form.shippingCost = this.book.shipping_cost ?? '';
      this.form.takenBy = this.employees.find(({ uid }) => uid === this.book.employee_uid) ?? available;
      this.form.photo = this.book.photoUrl;
      this.form.file = null;
    },
    resetFormValues() {
      this.form.title = '';
      this.form.author = '';
      this.form.description = '';
      this.form.category = '';
      this.form.language = '';
      this.form.purchaseDate = '';
      this.form.purchasePrice = '';
      this.form.shippingCost = '';
      this.form.takenBy = available;
      this.form.photo = '';
      this.form.file = null;
      this.image = '';
      this.isCoverChange = false;
    },
    handleHideModal() {
      this.$emit('onHide');
    },
    handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('onSubmit', {
          data: {
            inventory_code: this.inventoryCode,
            title: this.form.title,
            author: this.form.author,
            description: this.form.description,
            purchase_date: this.form.purchaseDate,
            purchase_price: this.form.purchasePrice,
            shipping_cost: this.form.shippingCost,
            library_item_category_id: this.form.category.id,
            library_item_language_id: this.form.language.id,
            employee_uid: this.form.takenBy?.uid ?? null,
            taken_date: this.form.takenBy?.uid ? new Date().toISOString().split('T')[0] : null,
          },
          file: this.form.file,
          isCoverChange: this.isCoverChange
        });
      }
    },
    handleDelete() {
      this.$bvModal.msgBoxConfirm('Delete this book?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            this.$emit('onDelete');
          }
        });
    },
    handleChangeCover(e) {
      if (!(e && e.target.files.length)) {
        return;
      }
      this.isCoverChange = true;
      this.form.file = e.target.files[0];
      const reader = new FileReader()
      reader.onload = (e) => {
        this.image = e.target.result;
      }
      reader.readAsDataURL(e.target.files[0])
    }
  },
  computed: {
    employees() {
      return [
        { name: 'Available', uid: null },
        ...this.$store.state.library.employees
      ];
    },
    categories() {
      return this.$store.state.library.categories;
    },
    languages() {
      return this.$store.state.library.languages;
    },
    canDelete() {
      return this.mode === FORM_MODE.EDIT;
    },
    modalTitle() {
      return `#${this.inventoryCode}-${this.mode === FORM_MODE.CREATE ? 'Creating' : 'Editing'} Book`;
    }
  },
  watch: {
    open(val) {
      if (val) {
        if (this.mode === FORM_MODE.EDIT) {
          this.fillFormValues();
        }
        this.$bvModal.show(this.modalId);
      } else {
        this.$bvModal.hide(this.modalId);
        this.$v.$reset();
        this.resetFormValues();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.book {
  width: 140px;
  height: 200px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  &__cover {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      margin: -10px;
      object-fit: cover;
    }
  }

  &__title {
    color: #fff;
    font-size: 13px;
    margin-top: 15px;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
  }

  &__divider {
    height: 1px;
    border: none;
    width: 100%;
    margin: 10px 0px;
    text-align: center;
    background: rgba(255, 255, 255, 0.20);
  }

  &__author {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    font-style: normal;
    color: rgba(255, 255, 255, 0.60);
  }

  &__clear-icon {
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #DC3545;
    position: absolute;
  }
}
.file-input {
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 30px;
  color: #fff;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);

  label {
    margin: 0;
    width: 100%;
    cursor: pointer;
    line-height: 30px;
    text-align: center;
  }
}
:deep(.form-control-sm) {
  border-radius: 4px;
}
:deep(.form-group) {
  margin-bottom: 15px;

  label {
    color: #343A40;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0px;
  }

  textarea {
    height: 100px;
  }

  .multiselect {
    height: 27px;
    min-height: 27px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &__input {
      padding: 0;
    }

    &__tags {
      padding: 2px 40px 2px 8px !important;
      min-height: 27px !important;
    }

    &__placeholder {
      padding-top: 1px !important;
    }

    &__select {
      top: 0px;
      right: 0px;
      padding: 0 !important;
      height: 100% !important;

      &::before {
        margin: 0;
        left: 50%;
        right: unset;
        transform: translate(-50%, -50%) !important;
      }
    }

    &__single {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
:deep(.modal-body) {
  padding: 20px;
}
:deep(.modal-footer) {
  padding: 12px 20px;

  .btn {
    width: 94px;
  }
}
</style>
