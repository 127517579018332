<template>
  <div
    v-if="color"
    :class="['book-tag', `book-tag_${color}`]"
  >
    {{ tag }}
  </div>
</template>

<script>
const colors = {
  new: 'red',
  available: 'green',
  'not available': 'yellow'
}

export default {
  name: 'BookTag',
  props: {
    tag: {
      type: String,
      required: true
    }
  },
  computed: {
    color() {
      return this.tag.includes('taken by') ? colors['not available'] : colors[this.tag]
    }
  }
}
</script>

<style lang="scss" scoped>
.book-tag {
  padding: 0 4px;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  border-radius: 4px;

  &_red {
    color: #DC3545;
    background: rgba(244, 106, 106, 0.18);
  }


  &_green {
    color: #28A745;
    background: rgba(52, 195, 143, 0.18);
  }

  &_yellow {
    color: #FFC107;
    background: rgba(241, 180, 76, 0.18);
  }
}
</style>
